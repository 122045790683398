<template>
  <div id="user-dashboard">
    <header>
      <div>
        <i class="fas fa-home"></i>
        <label>Witaj {{ user.displayName }}</label>
      </div>

      <i class="fas fa-power-off" @click="logout"></i>
    </header>

    <div class="content">
      <h1 class="title white-text">Wybierz grę</h1>
      <div v-for="(game, index) in sortGames(games)" :key="index">
        <!-- <div> -->
          <div @click="gotoGame(game.url, game.gameComplete)" v-if="game.active" :id="`game${parseInt((game.url).match(/\d/g).join(''), 10)}-card`" class="card">
            <div>
              <h1 class="title">{{ game.name }}</h1>
              <h2 v-if="game.gameComplete && game.pass" class="subtitle">HASŁO: {{ game.pass }}</h2>
              <h2 v-if="game.gameComplete" class="subtitle"><i class="fas fa-star"></i> {{ game.userPoints }} <i style="margin-left:1rem;" class="fas fa-clock"></i> {{ getEndTime(game.startDateTime.toDate(), game.endDateTime.toDate()) }}</h2>
            </div>
            
            <img class="card-character" height="80" :src="`/game${parseInt((game.url).match(/\d/g).join(''), 10)}/player.png`">
          </div>
        <!-- </div> -->
      </div>
    </div>
    
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
  data() {
    return {
      user: {},
      games: {}
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user =>  {
      if (user) {
        this.$store.commit('SET_USER', user);
        this.fetchUser();
      } else {
        this.$router.push('/login');
      }
    });
  },
  methods: {
    logout() {
      firebase.auth().signOut().then(() => {
        this.$router.push('/');
        this.$store.commit('LOGOUT_USER');
      });
    },
    fetchUser() {
      this.user = this.$store.state.currentUser;
      this.fetchGames();
    },
    async fetchGames() {
      const db = firebase.firestore();
      // const ref = db.collection('users').doc(this.user.uid);
      // const doc = await ref.get();
      // if (doc.exists) {
      //   console.log(doc.data().games)
      //   this.games = doc.data().games;
      // } else {
      //   console.log("Error");
      // }
      
      db.collection('users').where("email", "==", this.user.email).onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            this.games = querySnapshot.docs[0].data().games;
          }
          if (change.type === 'modified') {
            this.games = querySnapshot.docs[0].data().games;
          }
          if (change.type === 'removed') {
            this.games = querySnapshot.docs[0].data().games;
          }
        });
      });
    },
    sortGames(games) {
      let arr = Object.values(games);
      return arr.sort((a,b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0));
    },
    gotoGame(gameUrl, gameComplete) {
      if (!gameComplete) {
        setTimeout(() => { 
          this.$router.push({ path: gameUrl });
        }, 400);
      }
    },
    gameTitle(name) {
      return name.split(" - ")[0];
    },
    gameSubtitle(name) {
      return name.split(" - ")[1];
    },
    getEndTime(startTime, endTime) {
      let timer = null;
      
      let diffDate = endTime.getTime() - startTime.getTime();

      let ms = diffDate % 1000;
      diffDate = (diffDate - ms) / 1000;
      let sec = diffDate % 60;
      diffDate = (diffDate - sec) / 60;
      let min = diffDate % 60;
      let hrs = (diffDate - min) / 60;

      if (sec < 10) sec = "0" + sec;
      if (min < 10) min = "0" + min;
      if (hrs < 10) hrs = "0" + hrs;

      timer = hrs + ":" + min + ":" + sec;
      
      return timer;
    }
  }
}
</script>

<style scoped>
header {
  width: 100%;
  height: 50px;
  background: #17398D;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  z-index: 10;
}
header > div {
  display: flex;
  align-items: center;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-left: .5rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.content {
  margin-top: 50px;
  padding: 1rem;
  background: linear-gradient(180deg, #42b983, #F0A824);
  min-height: calc(100vh - 50px);
  text-align: left;
}
.title {
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: 'Oswald', sans-serif;
}
.card {
  width: 100%;
  height: 180px;
  margin-bottom: 2rem;
  border-radius: .5rem;
  /* padding: 1rem; */
  display: block;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 20px -5px #000;
  border-bottom: 2px solid goldenrod;
}
.card:hover {
  animation: pushButton .3s linear;
}
#game1-card {
  background: url('/game1/game1_card.jpg');
  background-size: cover;
  background-position: bottom;
}
#game2-card {
  background: url('/game2/game2_card.jpg');
  background-size: cover;
  background-position: bottom;
}
#game3-card {
  background: url('/game3/game3_card.jpg');
  background-size: cover;
  background-position: bottom;
}
#game4-card {
  background: url('/game4/game4_card.jpg');
  background-size: cover;
  background-position: bottom;
}
.card > div {
  border-radius: .5rem;
  background: linear-gradient(90deg, black, transparent);
  padding: .6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}
.card .title {
  font-size: 1rem;
  color: #fff;
  text-shadow: 0 0 5px #000;
  margin-bottom: 0.3rem;
  font-family: 'Caveat Brush', cursive;
}
.card .subtitle {
  font-size: 1rem;
  color: #fff;
  text-shadow: 1px 1px 5px #000;
  text-transform: uppercase;
}
.card-character {
  margin: 3rem .5rem;
  transform: scale(1.7);
}

@keyframes pushButton {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1.05);
  }
}
</style>